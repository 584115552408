export default function FbLoginIcon(props) {
  return (
    <svg viewBox='0 0 213 213' preserveAspectRatio='xMinYMin' className={props.className}>
      <path
        d='M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,0,1,0,-32 0'
        className='f_logo_circle'
        fill='#1877F2'
      ></path>
      <path
        d='M90,212v-75h-27v-31h27v-25q0,-40 40,-40q15,0 24,2v26h-14q-16,0 -16,16v21h30l-5,31h-27v75a106 106,1,0,1,-32 0'
        className='f_logo_f'
        fill='transparent'
      ></path>
    </svg>
  );
}
