'use client';
import React from 'react';
import {
  AuthLoading,
  AuthLoadingMessage,
  AuthLoadingMessageProps,
  AuthLoadingProps
} from '#/src/components/auth/AuthLoading';

export const AuthRedirecting = (props: AuthLoadingProps) => (
  <AuthLoading msg={'Redirecting...'} {...props} />
);

export const AuthRedirectingMessage: React.FC<AuthLoadingMessageProps> = (props) => (
  <AuthLoadingMessage msg={'Redirecting...'} {...props} />
);
