'use client';
import { confirmSignUp, resendSignUpCode } from '@aws-amplify/auth';
import { SignUpOutput } from 'aws-amplify/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Alert, Button, FormField, TextInput } from '#/packages/ui';
import { useAnalytics } from '#/src/app/analytics';
import { SubmitButton } from '#/src/components/auth/SubmitButton';
import { Anchor, NavAnchor } from '#/src/components/NavAnchor';

interface ConfirmSignupFormProps {
  username: string;
  shouldResend: boolean;

  onNext(res: SignUpOutput): void;
}

export default function ConfirmSignupForm(props: ConfirmSignupFormProps) {
  const resend = props.shouldResend;
  const [canResend, resendConfirm] = useResendConfirmSignup(props.username);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm();

  const analytics = useAnalytics();

  const onSubmit = useCallback(async (data: FieldValues) => {
    try {
      const res = await confirmSignUp({ username: props.username, confirmationCode: data.code });
      props.onNext(res);
    } catch (e: any) {
      await analytics.track('auth.signup.error', {
        reason: 'apiError',
        message: e.message
      });
      console.error(e);
      setError('root.apiError', { ...e, message: e.message });
    }
  }, []);

  useEffect(() => {
    analytics.track('auth.signup.confirm-code.show');

    if (resend) {
      resendConfirm();
    }
  }, []);

  return (
    <form className='flex flex-col gap-y-4 mt-4' onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-y-1'}>
        <span>
          You just received a code on email: <span className={'font-medium'}>{props.username}</span>
        </span>
        <span>If it isn't in your inbox, check your spam folder.</span>
      </div>
      <FormField id={'code'} errors={[errors.code && 'Required']}>
        <TextInput
          color={'neutral'}
          id='code'
          placeholder={'Enter code'}
          {...register('code', { required: true })}
          type='number'
        />
      </FormField>
      {canResend && (
        <Anchor size={'sm'} onClick={resendConfirm}>
          Resend code
        </Anchor>
      )}
      {errors.root?.cognitoError && <Alert>{errors?.root?.cognitoError?.message}</Alert>}
      {errors.root?.apiError && <Alert>{errors?.root?.apiError?.message}</Alert>}
      <SubmitButton isLoading={isSubmitting} id={'confirm-code-btn'}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </SubmitButton>
    </form>
  );
}

function useResendConfirmSignup(email: string): [boolean, () => Promise<void>] {
  const [canSend, setCanSend] = useState(true);
  return [
    canSend,
    useCallback(
      () =>
        resendSignUpCode({ username: email }).then((res) => {
          setCanSend(false);
          setTimeout(() => {
            setCanSend(true);
          }, 10000);
        }),
      []
    )
  ];
}
