import { Button, ButtonProps } from '#/packages/ui';
import React from 'react';

export const SubmitButton = (props: ButtonProps) => (
  <Button
    size='xl'
    color='secondary'
    type='submit'
    wFull
    justify='center'
    flex={true}
    className={
      'mt-0 dark:bg-[rgb(79,79,200)] dark:border-[rgb(79,79,200)] dark:text-white dark:hover:bg-[rgb(53,53,170)] dark:hover:border-[rgb(53,53,170)]'
    }
    {...props}
  />
);
