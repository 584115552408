import React from 'react';

export const Separator = (props) => (
  <div className='relative my-4'>
    <div className='absolute inset-0 flex items-center gap-x-4' aria-hidden='true'>
      <div className='w-full border-t border-gray-200 dark:border-gray-500' />
      <span className={'text-sm text-gray-500 dark:text-white'}>{props.children || 'or'}</span>
      <div className='w-full border-t border-gray-200 dark:border-gray-500' />
    </div>
    <div className='opacity-0'>-</div>
  </div>
);
