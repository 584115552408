'use client';
import { autoSignIn } from '@aws-amplify/auth';
import { SignInOutput, signOut } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Alert } from '#/packages/ui/src/components/Alert';
import { AuthRedirectingMessage } from '#/src/components/auth/AuthRedirecting';

interface AuthAutoSigninStepProps {
  onNext(output: SignInOutput): void;
}

export function AuthAutoSigninStep(props: AuthAutoSigninStepProps) {
  const [error, setError] = useState();
  const autoSigninFlow = () => {
    return autoSignIn()
      .then((output) => {
        props.onNext(output);
      })
      .catch((error) => {
        if (error.name == 'UserAlreadyAuthenticatedException') {
          return signOut().then(() => {
            return { output: { nextStep: { signInStep: 'LOGIN' } } };
          });
        } else {
          console.error(error);
          setError(error);
          Promise.resolve();
        }
      });
  };

  useEffect(() => {
    autoSigninFlow();
  }, []);

  if (error) {
    return <Alert>Something went wrong</Alert>;
  }

  return <AuthRedirectingMessage msg={'Signing in...'} />;
}
